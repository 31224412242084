.b-r-5 {
  border-radius: 5px;
}
.b-r-10 {
  border-radius: 10px;
}
.b-r-15 {
  border-radius: 15px;
}
.b-r-20 {
  border-radius: 20px;
}
.b-r-25 {
  border-radius: 25px;
}
.b-r-30 {
  border-radius: 30px;
}
.b-r-35 {
  border-radius: 35px;
}
.b-r-50 {
  border-radius: 50%;
}

.pb-8 {
  padding-bottom: 80px;
}
.mb-8 {
  margin-bottom: 80px;
}
.pb-9 {
  padding-bottom: 90px;
}
.mb-9 {
  margin-bottom: 90px;
}
.bottom-10{
  bottom: 10px;
}


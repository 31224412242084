/* .bg-get-started {
  background-image: url(../images/get-started1.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vw;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(14, 0, 0, 0.1);
} */
.overlay-loading {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 999999;
}

.ongoing-payment-spin {
  background-color: #ffffff;
}

.logo-img {
  height: 40vw;
  width: 40vw;
  margin-top: 30vh;
}

.btn-hadia {
  background-color: #035499 !important;
  color: #ffffff !important;
  border: none !important;
  outline: none !important;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-hadia {
  background-color: none !important;
  color: #035499 !important;
  border: 1px solid #035499;
  outline: none !important;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1)) !important;
}

.home-padding {
  padding-left: 7vw;
  padding-right: 7vw;
}

.btn-hadia:focus {
  color: #fff !important;
  border-color: none;
  border: none;
  box-shadow: none;
}

.btn-reg {
  background-color: #035499;
  border: none;
  outline: none;
  height: 50px;
  width: 60%;
  border-radius: 12px;
  font-weight: 400;
}

.btn-reg:focus {
  color: #fff !important;
  border-color: none;
  border: none;
  box-shadow: none;
}

.bg-hadia {
  background-color: #035499;
}

input:focus {
  outline: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control-sm {
  color: #000000;
  border: 0.01 solid #035499;
  outline: none;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
}

.w-200 {
  width: 200px !important;
}

/* ---------font-Weight start --------- */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

/* ---------font-Weight End --------- */

.img-splash1 {
  height: auto;
  width: 100%;
}

.active-dot {
  font-size: 2em;
  color: #035499;
}

.inactive-dot {
  font-size: 1em;
  color: #e5e5e5;
}

.header-fixed {
  position: sticky;
  z-index: 7;
  top: 0px;
}

.center-cropped {
  width: 100%;
}

.backbutton-fixed {
  position: sticky;
  z-index: 9999;
  background-color: #fff !important;
  top: 10vw;
}

.sign-up-input {
  padding: 15px 12px;
  border-radius: 15px;
}

.react-tel-input .form-control {
  height: 55px !important;
  border-radius: 15px !important;
  width: 100% !important;
  border: 1px solid #cacaca !important;
}

.react-tel-input .country-list .country {
  padding: 12px 15px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 15px 0 0 15px !important;
  border: 1px solid #cacaca !important;
}

.phone-input {
  height: 50px !important;
  border-radius: 15px;
}

.react-tel-input .country-list {
  margin: 0px 0 0px -1px !important;
  width: 314px !important;
  border-radius: 0 0 6px 6px !important;
}

.terms {
  color: #035499;
}

.confirm-text {
  line-height: 1em;
  padding-left: 5px;
  margin-top: 100px;
  margin-bottom: 10px;
}

.sign-up-check input[type="checkbox"] {
  padding: 10px;
  border: 1px solid #035499;
  border-bottom: 2px solid #035499;
  margin-top: 100px;
  margin-bottom: 10px;
}

.ant-select-selection-overflow {
  height: 51px !important;
}

.disabled-input {
  padding: 12px 12px;
}

.sign-up-button {
  /* position: fixed;
  bottom:10px;
  right:10px; */
  background-color: #035499;
  border: none;
  outline: none;
  width: 40vw;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999 !important;
}

.add-child-button {
  background-color: #035499;
  /* border: none; */
  outline: none;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999 !important;
}

/* .bg-white-fixed {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  height: 20vw;
  width: 100%;
  border-radius: 18px;
  font-weight: 500;
  z-index: 99999999 !important;
} */

.sign-up-button-fixed {
  position: fixed;
  bottom: 8px;
  right: 15px;
  background-color: #035499;
  border: none;
  outline: none;
  width: 40vw;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999 !important;
}

.skip-button-user {
  background-color: #035499;
  border: none;
  outline: none;
  width: 40vw;
  border-radius: 18px;
  font-weight: 500;
  z-index: -9999999999999 !important;
}

.next-button-user {
  background-color: #035499;
  border: none;
  outline: none;
  width: 45vw;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999 !important;
}

.next-qualification {
  background-color: #035499;
  border: none;
  outline: none;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999999 !important;
}

.add-new-education {
  background-color: #fff;
  border: 1px solid #035499;
  color: #035499;
  outline: none;
  border-radius: 18px;
  font-weight: 500;
  z-index: 9999999999999 !important;
}

.skip-button {
  /* right: 10;
    left: 10; */
  position: relative;
  margin-top: 10%;
  background-color: #035499;
  margin-bottom: 1em;
  margin-right: 15px;
  /* bottom: 0;
  margin-bottom: 10ex; */
}

.skip-button1 {
  /* right: 10;
    left: 10; */

  background-color: #035499;

  margin-top: 2em;
  /* margin-bottom: 10ex;  */
}

.address-title {
  color: #035499;
  font-size: 1.5em;
  font-weight: 500;
}

.data-all-title {
  color: #035499;
  font-size: 1.2em;
  font-weight: 500;
}

.language-title {
  color: #035499;
  font-size: 1.18em;
  font-weight: 500;
}

.data-title {
  color: #02608f78;
  font-size: 0.9em;
  font-weight: 500;
}

.present-title {
  color: #035499;
  font-size: 1.14em;
  font-weight: 500;
}

.btn-fixed {
  position: fixed;
  z-index: 7;
  bottom: 15vw;
  width: 100%;
}

.title {
  color: #035499;
  font-size: 1.5em;
  font-weight: 500;
}

.subTitle {
  color: rgba(42, 41, 41, 0.619);
  font-size: 15px;
}

.freelance-subTitle {
  color: rgba(42, 41, 41, 0.619);
  font-size: 13px;
}

.Input-badge {
  position: absolute;
  margin-top: 18px;
  right: 10px;
  color: #035499;
  padding: 4px 7px;
  border: 1px solid #035499;
  /* z-index: 22; */
}

.radio-button {
  /* position: absolute; */
  margin-top: 5px;
  right: 10px;
  color: #035499;
  padding: 4px 7px;
  border: 1px solid #035499;
  border-radius: 20px;
}

.icons {
  font-size: 1.2em;
  text-align: center;
  color: #035499;
  background-color: #03549925;
  border-radius: 18px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.ant-switch-checked {
  background: #035499 !important;
}

.bottom-bar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  background-image: radial-gradient(circle at 50vw 10px,
      transparent 35px,
      #ffffff 37px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}

.circle {
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: 28px;
  margin: auto;
  left: 178px;
  background-color: #2f0ed6;
  border-radius: 50%;
}

.touch-ink {
  color: #aaaaaa;
}

.footer-inactive {
  color: #c6c6c6 !important;
  font-size: large;
}

.footer-active {
  color: #035499 !important;
  font-size: larger;
}

.fs-7 {
  font-size: 0.8em !important;
}

.home-icon {
  font-size: 2.5em;
}

.hadia-blue {
  color: #035499;
}

.text-hadia {
  color: #035499;
}

.hadia-bg-blue {
  background-color: #035499;
}

.membership-date-blue {
  background: rgba(103, 164, 255, 0.2);
  box-shadow: inset 0px 4px 50px #ffffff;
  backdrop-filter: blur(10px);
}

.progress-bar {
  background-color: #035499 !important;
  border-radius: 20px;
}

.complete-profile-badge {
  background: #e5e5e5;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
}

.border-home-card {
  border: 2px solid rgba(2, 97, 143, 0.5);
}

.border-left-post {
  border-left: 6px solid #035499;
}

.event-card {
  background-color: #f3f3f3;
}

.loan-background {
  width: 100%;
  height: 45vw;
  object-fit: cover;
  border-radius: 15px;
  background-image: url("../images/education.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery-category {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.text-hadia-gray {
  color: #91919f;
}

.ant-modal-header {
  border-radius: 8px 8px 0px 0px !important;
}

.ant-modal-footer {
  border-radius: 0px 0px 8px 8px !important;
}

.ant-btn-default {
  border-radius: 5px !important;
  color: #035499 !important;
}

.ant-btn-primary {
  border-radius: 5px !important;
  color: #ffffff !important;
  background-color: #035499 !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 9px 11px !important;
  height: 55px !important;
}

.ant-btn-lg {
  height: 50px !important;
}

.ant-select-open {
  font-size: 1.5em !important;
  height: 5vw !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 15px !important;
}

.course-image-container {
  width: 90vw;
  position: relative;
}

.course-image {
  width: 100%;
  border-radius: 12px;
}

.enroll-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40vw;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;
}

.sign-course-container {
  width: 90vw;
}

.sign-course-container h3 {
  color: #035499;
}

.single-image-container {
  width: 100%;
}

.single-image-container img {
  border-radius: 10px;
  margin-top: 50px;
  width: 90vw;
}

.bar {
  width: 1.5vw;
  height: 6vw;
  background-color: #035499;
  margin-right: 5px;
}

.join-button-container {
  width: 95vw;
  margin-top: 40px;
}

.join-button {
  padding: 10px 20px;
  width: 30vw;
  background-color: #035499;
  outline: none;
  border: none;
  border-radius: 12px;
}

.bottom-button {
  position: fixed;
  z-index: 9999;
  width: 90%;
  bottom: 10px;
}

.card {
  align-items: center;
  width: 24.5px;
  height: 5px;
  align-items: center;
  background-color: hsla(240, 100%, 99%, 0.707);
  margin-bottom: 15px;
}

.card2 {
  align-items: center;
  width: 24.5px;
  height: 5px;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  margin-bottom: 15px;
}

.job-card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
}

.hadia-heading {
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #035499;
}

/* aboutUs */

.about-us-container {
  height: 50vh;
}

.about-us-container .heading {
  font-weight: 800;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  color: #035499;
  margin-top: 10vw;
}

.about-us-container .about-us-content {
  width: 90vw;
  text-align: justify;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #035499;
  margin-top: 20px;
}

.active {
  background-color: #ffffff;
}

.annual {
  border-radius: 30px;
  width: 45vw;
  height: 50vw;
  object-fit: cover;
  background-image: url("../images/fund.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.annual-fund {
  width: 100%;
  height: 65vw;
  object-fit: cover;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 2px 8px 8px 0px #00000020;
  background-image: url("../images/fund.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.my-donation {
  border-radius: 30px;
  width: 45vw;
  height: 50vw;
  object-fit: cover;
  background-image: url("../images/donation.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.donation {
  width: 100%;
  height: 65vw;
  object-fit: cover;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 2px 8px 8px 0px #00000020;
  background-image: url("../images/donation.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.membership-page {
  border-radius: 30px;
  width: 45vw;
  height: 50vw;
  object-fit: cover;
  background-image: url("../images/membership.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.membership {
  width: 100%;
  height: 65vw;
  object-fit: cover;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 2px 8px 8px 0px #00000020;
  background-image: url("../images/membership.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.public-fund-raising {
  width: 45vw;
  height: 50vw;
  object-fit: cover;
  border-radius: 30px;
  background-image: url("../images/fund-raising.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.fund-raising {
  width: 100%;
  height: 65vw;
  object-fit: cover;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 2px 8px 8px 0px #00000020;
  background-image: url("../images/fund-raising.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.my-zakat {
  width: 45vw;
  height: 50vw;
  object-fit: cover;
  border-radius: 30px;
  background-image: url("../images/zakat.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.zakat {
  width: 100%;
  height: 65vw;
  object-fit: cover;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 2px 8px 8px 0px #00000020;
  background-image: url("../images/zakat.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.b-4-s {
  border-left: 2px solid #035499;
  border-top: 4px solid #035499;
  border-right: 2px solid #035499;
  border-bottom: 4px solid #035499;
}

.active-round {
  background-color: #fff;
  border-radius: 100%;
  border: 3px solid #035499;
  outline: 2px solid #fff;
}

.error-message {
  color: red;
}

.btn-job-open {
  border: 1px solid #035499;
}

.border-form-muted {
  border: 1px solid #f1f1fa;
  color: #91919f;
}

.settings-text {
  font-weight: 500;
  font-size: 16px;
  color: #035499;
}

.search-input {
  height: 50px;
  border: 1px solid #035499;
  border-radius: 14px 14px 14px 14px;
  /* border-top-color: 1px solid #035499 !important; */
  /* border-right-color: #ffffff !important; */
  outline: none;
}

.search-input:active {
  border: 1px solid #035499;
  outline: none !important;
  /* border-right-color: #ffffff !important; */
  border-radius: 14px 0px 0px 14px;
}

.search-card {
  outline: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.profile-card {
  background: rgba(2, 97, 143, 0.1);
  border-radius: 15px;
}

.freelance-card {
  background: #f3f3f3;
  border-radius: 20px;
}

.select2-search {
  border-radius: 20px;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

/* .search-input-addon {
  background-color: #fff !important;
  border: 1px solid #035499;
  border-left-color: #ffffff !important;
  border-radius: 0px 14px 14px 0px;
  outline: none !important;
} */
/* .search-input {
  border: 1px solid #035499;
  border-radius: 14px 0px 0px 14px;
  border-top: 1px solid #035499 !important;
  outline: none;
}

.search-input:active {
  border: 1px solid #035499;
  outline: none !important;
  border-radius: 14px 0px 0px 14px;
}

.search-input-addon {
  background-color: #fff !important;
  border: 1px solid #035499;
  border-radius: 0px 14px 14px 0px;
  outline: none !important;
} */
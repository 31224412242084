.career-status-check input[type="checkbox"] {
  padding: 10px;
  border: 1px solid #035499;
  border-bottom: 2px solid #035499;
  outline: none;
  margin-right: 10px;
}

.career-status-radio input[type="radio"] {
  border: 1px solid #035499;
}

.alert {
  top: "30px";
  bottom: "10px";
  left: "30px";
  right: "50px";
}
.vector-contain {
  position: relative;
  width: 120px;
  height: 160px;
  margin: 0px auto;
  padding-top: 30px;
  margin-bottom: 20px;
}
.vector {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px dashed #ffffff;
  box-sizing: border-box;
}
.vector img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}
.icon {
  position: relative;
  padding: 8px 13px;
  border-radius: 50%;
  background-color: #035499;
  color: white;
  height: 35px;
  width: 35px;
  margin-top: 130px;
  /* bottom: 11px;
  right:-4px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.remove-icon {
  position: absolute;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #ffffff;
  top: 40px;
  left: -1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.employed-title {
  color: #035499;
  font-size: 1.2em;
  font-weight: 500;
}
.employed-date {
  color: #035499;
  font-size: 1.2em;
  font-weight: 500;
}
.employe-date {
  color: #035499;
  font-size: 1em;
  font-weight: 500;
}
.password {
  position: relative;
}
.password-visible {
  position: absolute;
  right: 27px;
  top: 29px;
  z-index: 1;
}

.profile-image {
  display: flex;
  justify-content: center;
  position: relative;
}

.profile {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 3px;
  border: 3px dotted #035499;
}

.profile-icon {
  position: absolute;
  padding: 8px 13px;
  border-radius: 50%;
  background-color: #035499;
  color: white;
  bottom: 15px;
  right: -2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.sucess-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.check-icon {
  font-size: 2.5rem;
  margin-bottom: 5px;
  color: #035499;
}
.success-text {
  font-size: 15px;
}
.ant-modal-content {
  border-radius: 20px !important;
}

.edit-icon {
  position: relative;
}
.icon {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 22;
}

.circular-container {
  width: 90vw;
  height: 25vw;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 24px;
  vertical-align: middle;
}
.circular-container p {
  margin: 0px;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
}
.circular-container-child {
  width: 90%;
}
.download-container {
  width: 90vw;
  height: 25vw;
  border: 1px solid #eeeeee;
  border-radius: 24px;
  vertical-align: middle;
}

.usefullLinks-container {
  width: 95vw;
  border: 1px solid #eeeeee;
  border-radius: 24px;
  padding: 10px;
  vertical-align: middle;
  background-color: #f3f3f3;
}
.usefullLinks-container-child {
  width: 100%;
}
.usefullLinks-container-child img {
  width: 100%;
}
.links-container {
  padding: 5px;
  border-radius: 14px;
  width: 100%;
}
.links-container p {
  margin: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #035499;
}
.links-container a {
  background-color: #035499 !important;
  outline: none;
}

.projects-button-container {
  width: 90vw;
  margin: 0 auto;
}

.projects-button {
  width: 45%;
  background: rgba(2, 97, 143, 0.1);
  border-radius: 10px;
  border: none;
  color: #035499;
}

.projects-button-active {
  width: 45%;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #035499;
}

.department-cell {
  padding: 12px;
  border: 1px solid #fff;
  border-radius: 12px;
  background-color: #035499;
}
